import { FC, useState, useEffect } from 'react';
import EXPERIENCES_CSS from '../../style/layouts/ExperiencesCss';
import STYLE_CSS from '../../style/StyleCss';
import PictoDisplayer from './PictoDisplayer';

interface ExperienceProps {
  id: number,
  trigger?: boolean,
  experience: {
    society: string,
    location: string,
    years: string,
    job1: string,
    job2: string,
    description1: string,
    description2: string,
    description3: string,
    description4: string,
    skills: string[]
  }
}

const Experience: FC<ExperienceProps> = ({ experience, trigger, id=0 }) => {
  const [visibleClassName, setVisibleClassName] = useState<string>('experience-closed');
  
  useEffect(() => {
    let time = 40 * id;

    if (trigger) {
      const timeoutId = setTimeout(() => {
        setVisibleClassName('experience-opened');
      }, time);
      return () => clearTimeout(timeoutId);
    } else {
      const timeoutId = setTimeout(() => {
        setVisibleClassName('experience-closed');
      }, time);
      return () => clearTimeout(timeoutId);
    }
  }, [trigger]);

  const handleToggle = () => {
    if(visibleClassName === 'experience-closed') setVisibleClassName('experience-opened');
    else setVisibleClassName('experience-closed');
  };

  let pictos = experience.skills.map(function(skill, index){
    return (
      <PictoDisplayer
        key={index}
        picto={skill}
      />
    )
  })

  return (
    <_ExperienceWrapper onClick={handleToggle} className={visibleClassName}>
      <_Experience__TopInformations>
        <_Experience__TopInformations_MajorInformation>
          <_H3>{experience.society}</_H3>
          <_P>{experience.location}</_P>
        </_Experience__TopInformations_MajorInformation>
        <_Experience__TopInformations_MinorInformation>
          <_P>{experience.years}</_P>
        </_Experience__TopInformations_MinorInformation>
      </_Experience__TopInformations>

      <_Experience__BottomInformations className={visibleClassName}>
        <_Experience__Info>
          <_P__Weight>{experience.job1}</_P__Weight>
          <_P__Weight>{experience.job2}</_P__Weight>
        </_Experience__Info>
        <_Experience__Info>
          <_P>{experience.description1}</_P>
          <_P>{experience.description2}</_P>
          <_P>{experience.description3}</_P>
          <_P>{experience.description4}</_P>
          <_Experience__Picto>
            {pictos}
          </_Experience__Picto>
        </_Experience__Info>
      </_Experience__BottomInformations>
    </_ExperienceWrapper>
  );
};

const
  _ExperienceWrapper = EXPERIENCES_CSS._ExperienceWrapper,
  _Experience__TopInformations = EXPERIENCES_CSS._Experience__TopInformations,
  _Experience__TopInformations_MajorInformation = EXPERIENCES_CSS._Experience__TopInformations_MajorInformation,
  _Experience__TopInformations_MinorInformation = EXPERIENCES_CSS._Experience__TopInformations_MinorInformation,
  _Experience__BottomInformations = EXPERIENCES_CSS._Experience__BottomInformations,
  _Experience__Picto = EXPERIENCES_CSS._Experience__Picto,
  _Experience__Info = EXPERIENCES_CSS._Experience__Info;

const 
  _H3 = STYLE_CSS._H3,
  _P__Weight = STYLE_CSS._P__Weight,
  _P = STYLE_CSS._P;

export default Experience;