import styled from 'styled-components';
import VARIABLES_CSS from '../VariablesCss';

const Navigation = {
  _NavigationWrapper: styled.div`
    padding-top: ${VARIABLES_CSS.spacer.lg};
    justify-content: space-between;
    position: sticky;
    display: flex;
    width: 100%;

    @media (max-width: 1024px) {
      padding-top: ${VARIABLES_CSS.spacer.lg};
      margin-top: ${VARIABLES_CSS.spacer.lg};
      justify-content: space-around;
      position: relative;
      width: fit-content;
      width: 100%;
    }
    
    & span {
      @media (max-width: 1024px) {
        display: none;
      }
    }
  `,
};

export default Navigation;