import { FC, useState, useEffect } from 'react';
import STYLE_CSS from '../../style/StyleCss';
import TOPINFORMATIONS_CSS from '../../style/layouts/TopInformationsCss';
import utilsService from '../../services/utilsService';
import Button from '../core/Button';

interface TopInformationsProps {
  firstName: string,
  lastName: string,
  bio: string,
  displayTrigger: boolean,
  darkMode: boolean,
  handleScroll?: () => void,
}

const TopInformations: FC<TopInformationsProps> = ({ firstName, lastName, bio, displayTrigger, darkMode, handleScroll }) => {
  const [displayClassName, setDisplayClassName] = useState<string>('picture-hide');
  const [top, setTop] = useState('');

  useEffect(() => {
    if (displayTrigger) {
      setDisplayClassName('picture-show');
    } else {
      setDisplayClassName('picture-hide');
    }

    const handleScroll = () => {
      const windowWidth = window.innerWidth;
      if(windowWidth > 1024){
        const windowHeightDecal = utilsService.calculateWindowTopDecal();
        setTop(`calc(${windowHeightDecal}px)`);
      }
    };
    window.addEventListener('scroll', handleScroll);
  }, [displayTrigger]);

  return (
    <_TopInformationsWrapper
      id='topInformations'
      style={{top}}
      className={displayClassName}
    >
      <_TopInformations__Picture className={displayClassName}/>

      <_TopInformations__InformationsContainer>
        <_H1 style={{marginRight: '-10px'}}>{lastName}</_H1>
        <_H2 style={{marginRight: '-10px'}}>{firstName}</_H2>
        <_H4>{bio}</_H4>
      </_TopInformations__InformationsContainer>

      <_TopInformations__ScrollButton
        className={displayClassName}
      >
        <Button
          darkMode={darkMode}
          handleClick={handleScroll}
          arrow='bottom'
        />
        <_P>CLICK<br/>or SCROLL</_P> 
      </_TopInformations__ScrollButton>
    </_TopInformationsWrapper>
  );
};

const
  _TopInformationsWrapper = TOPINFORMATIONS_CSS._TopInformationsWrapper,
  _TopInformations__Picture = TOPINFORMATIONS_CSS._TopInformations__Picture,
  _TopInformations__ScrollButton = TOPINFORMATIONS_CSS._TopInformations__ScrollButton,
  _TopInformations__InformationsContainer = TOPINFORMATIONS_CSS._TopInformations__InformationsContainer;

const 
  _H1 = STYLE_CSS._H1,
  _H2 = STYLE_CSS._H2,
  _H4 = STYLE_CSS._H4,
  _P = STYLE_CSS._P;

export default TopInformations;