import { FC, useState, useEffect } from 'react';
import LAYOUT_CSS from '../../style/layouts/LayoutCss';
import utilsService from '../../services/utilsService';
import TopInformations from './TopInformations';
import MinorInformations from './MinorInformations';
import Experiences from './Experiences';
import Navigation from './Navigation';
import Spacer from '../core/Spacer';
import Tab from '../layouts/Tab';

interface LayoutProps {
  darkMode: boolean,
  isFrench: boolean,
  toggleTab: () => void,
  isTabOpen: boolean,
  user: {
    firstName: string,
    lastName: string,
    bio: string,
    birthDate: string,
    phone: string,
    mail: string,
    street: string,
    zipCode: string
  },
  handleScroll?: () => void,
};

const Layout: FC<LayoutProps> = ({
  user,
  darkMode,
  handleScroll,
  isFrench,
  toggleTab,
  isTabOpen,
}) => {
  const [top, setTop] = useState<string>('');
  const [scrollTrigger, setScrollTrigger] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      const windowWidth = window.innerWidth;
      if(windowWidth > 1024){
        const scrollPosition = window.scrollY;
        const seuilDeDéclenchement = utilsService.calculateWindowTopDecal();

        if (scrollPosition > seuilDeDéclenchement) {
          setScrollTrigger(true)
        } else {
          setScrollTrigger(false)
        };

        const bumperElementHeight = utilsService.getElementHeight('topInformations');
        const windowHeightDecal = utilsService.calculateWindowTopDecal();
        if(bumperElementHeight) setTop(`calc(${windowHeightDecal + bumperElementHeight}px)`);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <_LayoutWrapper
      style={{color: utilsService.darkModeColorHandler(darkMode)}}
      id='layout' 
    >
      <Spacer height={30} />
      <TopInformations
        firstName={user.firstName}
        lastName={user.lastName}
        bio={user.bio}
        displayTrigger={scrollTrigger}
        darkMode={darkMode}
        handleScroll={handleScroll}
      />
      <Spacer height={90} />
      <_Layout__BottomInformations
        style={{top}}
      >
        <_Layout__BottomInformations__LeftPanel>
          <MinorInformations
            birthDate={user.birthDate}
            phone={user.phone}
            mail={user.mail}
            street={user.street}
            zipCode={user.zipCode}
          />
          <Spacer height={120} />
          <Navigation
            darkMode={darkMode}
            toggleTab={toggleTab}
          />
          <Spacer height={120} />
        </_Layout__BottomInformations__LeftPanel>
        
        <_Layout__BottomInformations__RightPanel>
          <Spacer height={70} />
          <Experiences
            isTabOpen={isTabOpen}
            isFrench={isFrench}
            darkMode={darkMode}
          />
          <Tab
            isTabOpen={isTabOpen}
          />
        </_Layout__BottomInformations__RightPanel>
      </_Layout__BottomInformations>
    </_LayoutWrapper>
  );
};

const
  _LayoutWrapper = LAYOUT_CSS._LayoutWrapper,
  _Layout__BottomInformations = LAYOUT_CSS._Layout__BottomInformations,
  _Layout__BottomInformations__LeftPanel = LAYOUT_CSS._Layout__BottomInformations__LeftPanel,
  _Layout__BottomInformations__RightPanel = LAYOUT_CSS._Layout__BottomInformations__RightPanel;

export default Layout;