import { FC, useEffect, useState } from 'react';
import EXPERIENCES_CSS from '../../style/layouts/ExperiencesCss';
import EXPERIENCES_DB from '../../services/experiencesDB';
import Experience from '../core/Experience';
import utilsService from '../../services/utilsService';
import Button from '../core/Button';
import STYLE_CSS from '../../style/StyleCss';
import TopInformations from './TopInformations';

interface ExperiencesProps {
  darkMode: boolean,
  isFrench: boolean,
  isTabOpen: boolean
}

const Experiences: FC<ExperiencesProps> = ({ darkMode, isFrench, isTabOpen }) => {
  const [top, setTop] = useState('');
  const [scrollTrigger, setScrollTrigger] = useState<boolean>(false);
  const [displayClassName, setDisplayClassName] = useState<string>('unblur-element');
  
  useEffect(() => {
    const handleScroll = () => {
      const windowWidth = window.innerWidth;
      if(windowWidth > 1024){
        const bumperElementHeight = utilsService.getElementHeight('topInformations');
        const windowHeightDecal = utilsService.calculateWindowTopDecal();
        if(bumperElementHeight) setTop(`calc(${windowHeightDecal + bumperElementHeight}px)`);

        if (utilsService.getNavigationInformationsTrigger()) {
          setScrollTrigger(true);
          setDisplayClassName('blur-element');
        } else {
          setScrollTrigger(false);
          setDisplayClassName('unblur-element');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
  }, []);

  const scrollTopFunc = () => {utilsService.scrollToTheTopAndCloseExepriences()}

  let experiences = EXPERIENCES_DB[isFrench ? 'fr' : 'en'].map(function(experience, index){
    return (
      <Experience
        key={index}
        id={index}
        trigger={scrollTrigger}
        experience={experience}
      ></Experience>
    )
  });

  return (
    <_ExperiencesWrapper
      id="experiences"
      className={isTabOpen ? 'tab-close' : 'tab-open'}
      style={{
        top,
        borderTop: utilsService.darkModeBorderHandler(darkMode)
      }}
    >
      <_ExperiencesWrapper__absolute>
        { experiences }
        <_Experience__Overlay className={displayClassName} />
      </_ExperiencesWrapper__absolute>

      <_Experiences__ScrollButton
        className={displayClassName}
      >
        <_P>CLICK<br/>or SCROLL</_P> 
        <Button
          darkMode={darkMode}
          handleClick={scrollTopFunc}
          arrow='top'
        />
      </_Experiences__ScrollButton>

      <_Experiences__ScrollCGV
        className={displayClassName}
      >
        <_H6>{new Date().getFullYear()} PRUSKI Jean - All rights reserved</_H6>
      </_Experiences__ScrollCGV>
    </_ExperiencesWrapper>
  );
};

const
  _ExperiencesWrapper = EXPERIENCES_CSS._ExperiencesWrapper,
  _ExperiencesWrapper__absolute = EXPERIENCES_CSS._ExperiencesWrapper__absolute,
  _Experiences__ScrollButton = EXPERIENCES_CSS._Experiences__ScrollButton,
  _Experiences__ScrollCGV = EXPERIENCES_CSS._Experiences__ScrollCGV,
  _Experience__Overlay = EXPERIENCES_CSS._Experience__Overlay;

const
  _P = STYLE_CSS._P,
  _H6 = STYLE_CSS._H6;

export default Experiences;