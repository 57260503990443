import VARIABLES_CSS from '../style/VariablesCss';

const utilsService = {
  calculatePercentWindowHeight: (inputPercent) => {
    const windowHeight = window.innerHeight;
    const percent = (inputPercent / 100) * windowHeight;
    return percent;
  },
  calculateWindowTopDecal: () => {
    const windowHeight = window.innerHeight;
    const topInformationsHeight = utilsService.getElementHeight('topInformations');
    const minorInformationsHeight = utilsService.getElementHeight('minorInformations');
    const navigationHeight = utilsService.getElementHeight('navigation');
    const elementsHeight = topInformationsHeight + minorInformationsHeight + navigationHeight;
    const percent = (windowHeight - elementsHeight) / 2 * .5;
    return percent;
  },
  getElementHeight: (id) => {
    const element = document.getElementById(id);
    return element?.offsetHeight ?? 0;
  },
  getElementLocation: (id) => {
    const element = document.getElementById(id);
    const rect = element.getBoundingClientRect();
    return rect?.y ?? 0;
  },
  getNavigationInformationsTrigger: () => {
    const topDecal = utilsService.calculateWindowTopDecal();
    const topInformationsHeight = utilsService.getElementHeight('topInformations');
    const minorInformationsHeight = utilsService.getElementHeight('minorInformations');
    const globalHeight = topDecal + topInformationsHeight + minorInformationsHeight;
    const navigationLocation = utilsService.getElementLocation('navigation');
    if(globalHeight >= navigationLocation) return true;
    else return false;
  },
  darkModeBackgroundColorHandler: (isDarkMode) => {
    if(isDarkMode) return VARIABLES_CSS.color.lightBlack;
    else return VARIABLES_CSS.color.lightWhite;
  },
  darkModeColorHandler: (isDarkMode) => {
    if(isDarkMode) return VARIABLES_CSS.color.white;
    else return VARIABLES_CSS.color.black;
  },
  darkModeBorderHandler: (isDarkMode, size=1) => {
    if(isDarkMode) return `${size}px solid ${VARIABLES_CSS.color.lightWhite}`;
    else return `${size}px solid ${VARIABLES_CSS.color.lightBlack}`;
  },
  scrollToTheTopAndCloseExepriences: () => {
    const currentScroll = window.scrollY;
    const scrollDistance = utilsService.calculatePercentWindowHeight(92);
    const totalFrames = 30 * .5;
  
    const scrollStep = scrollDistance / totalFrames;
  
    function scrollAnimation(currentFrame) {
      if (currentFrame <= totalFrames) {
        const newScrollPosition = currentScroll - scrollStep * currentFrame;
        window.scrollTo(0, newScrollPosition);
        requestAnimationFrame(() => scrollAnimation(currentFrame + 1));
      }
    }
    scrollAnimation(1);
  }
};

export default utilsService;
