import { FC } from 'react';
import { 
  SiAbletonlive, 
  SiAngularjs, 
  SiHtml5,
  SiCss3,
  SiGithub,
  SiBitbucket,
  SiFigma,
  SiJira,
  SiGitlab,
  SiAdobeindesign,
  SiAdobephotoshop,
  SiAdobeillustrator,
  SiApplemusic,
  SiHubspot,
  SiNotion,
  SiAmazonaws,
  SiNodedotjs,
  SiJavascript,
  SiPython,
} from "react-icons/si";
import { FaReact, FaTshirt } from "react-icons/fa";
import { GiSewingMachine } from "react-icons/gi";
import { SlGlobe } from "react-icons/sl";
import { HiOutlineCloud } from "react-icons/hi";
import { GrLinkedinOption } from "react-icons/gr";

import PICTODISPLAYER_CSS from '../../style/core/PictoDisplayerCss';

interface PictoDisplayerProps {
  picto: string,
  link?: string,
  onClickFunc?: () => void,
}

const PictoDisplayer: FC<PictoDisplayerProps> = ({ picto, link, onClickFunc }) => {
  return (
    <_PictoDisplayer
      href={link}
      target="_blank"
      onClick={onClickFunc}
      style={{
        cursor: 'pointer'
      }}
    >
      {picto === 'aws' ? (
        <SiAmazonaws />
      ) : picto === 'nodejs' ? (
        <SiNodedotjs />
      ) : picto === 'python' ? (
        <SiPython />
      ) : picto === 'react' ? (
        <FaReact />
      ) : picto === 'hubspot' ? (
        <SiHubspot />
      ) : picto === 'html' ? (
        <SiHtml5 />
      ) : picto === 'css' ? (
        <SiCss3 />
      ) : picto === 'gitlab' ? (
        <SiGitlab />
      ) : picto === 'figma' ? (
        <SiFigma />
      ) : picto === 'notion' ? (
        <SiNotion />
      ) : picto === 'angular' ? (
        <SiAngularjs />
      ) : picto === 'github' ? (
        <SiGithub />
      ) : picto === 'bitbucket' ? (
        <SiBitbucket />
      ) : picto === 'jira' ? (
        <SiJira />
      ) : picto === 'js' ? (
        <SiJavascript />
      ) : picto === 'ai' ? (
        <SiAdobeillustrator />
      ) : picto === 'ps' ? (
        <SiAdobephotoshop />
      ) : picto === 'id' ? (
        <SiAdobeindesign />
      ) : picto === 'machine' ? (
        <GiSewingMachine />
      ) : picto === 'couture' ? (
        <FaTshirt />
      ) : picto === 'song' ? (
        <SiApplemusic />
      ) : picto === 'live' ? (
        <SiAbletonlive />
      ) : picto === 'soundcloud' ? (
        <HiOutlineCloud />
      ) : picto === 'linkedin' ? (
        <GrLinkedinOption />
      ) : picto === 'globe' ? (
        <SlGlobe />
      ) : (
        <span>Unknwn</span>
      )}

    </_PictoDisplayer>
  );
};

const _PictoDisplayer = PICTODISPLAYER_CSS._PictoDisplayer;

export default PictoDisplayer;