import { FC, useState, useEffect } from 'react';
import utilsService from '../../services/utilsService';
import styled from 'styled-components';
import VARIABLES_CSS from '../../style/VariablesCss';

interface NavigationProps {
  isTabOpen?:boolean
}

const Navigation: FC<NavigationProps> = ({ isTabOpen }) => {
  const [top, setTop] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const windowWidth = window.innerWidth;
      if(windowWidth > 1024){
        const bumperElementHeight = utilsService.getElementHeight('topInformations');
        const windowHeightDecal = utilsService.calculateWindowTopDecal();
        if(bumperElementHeight) setTop(`calc(${windowHeightDecal + bumperElementHeight}px)`);
      }
    };

    window.addEventListener('scroll', handleScroll);
  }, []);

  return (
    <_TabWrapper
      className={isTabOpen ? 'tab-close' : 'tab-open'}
      style={{
        top,
      }}
    >
      tab
    </_TabWrapper>
  );
};

const _TabWrapper = styled.div`
  margin-bottom: ${VARIABLES_CSS.spacer.xxl};
  padding-top: ${VARIABLES_CSS.spacer.lg};
  transition: all .3s;
  position: sticky;
  transform-origin: center top;

  display: flex;
  width: 100%;

  &.tab-close{
    background-color: red;
    min-height: 400px;
  }

  &.tab-open{
    backdrop-filter: blur(10px);
    transform: scaleY(0);
    background-color: #80808005;
  }
`

export default Navigation;