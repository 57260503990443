import React, { useState, useEffect, useRef } from 'react';
import APP_CSS from './style/layouts/AppCss';
import Layout from './components/layouts/Layout';
import CoreThreeBackground from './components/core/CoreThreeBackground';
import utilsService from './services/utilsService';
import Switch from './components/core/Switch';

const App: React.FC = () => {
  const [darkMode, setDarkMode] = useState<boolean>(true);
  const [isFrench, setIfIsFrench] = useState<boolean>(true);
  const [isTabOpen, setIfIsTabOpen] = useState<boolean>(false);
  const topRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const darkModeFromLocalStorage = localStorage.getItem('isDarkMode');
    if(darkModeFromLocalStorage) setDarkMode(JSON.parse(darkModeFromLocalStorage.toLowerCase()));
    const langFromLocalStorage = localStorage.getItem('isFrench');
    if(langFromLocalStorage) setIfIsFrench(JSON.parse(langFromLocalStorage.toLowerCase()));
    if (topRef.current) topRef.current.scrollIntoView({ behavior: 'smooth' });

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  } else {
      window.location.reload();
  }
  };

  const handleDarkMode = () => {
    setDarkMode(!darkMode)
    localStorage.setItem('isDarkMode', JSON.stringify(!darkMode));
  };

  const toggleTab = () => {
    if(!isTabOpen) utilsService.scrollToTheTopAndCloseExepriences();
    setIfIsTabOpen(!isTabOpen);
  }

  const handleLang = () => {
    setIfIsFrench(!isFrench)
    localStorage.setItem('isFrench', JSON.stringify(!isFrench));
  };
  const handleScroll = () => scrollToBottom(3000);

  function scrollToBottom(duration: number = 1000): void {
    const element = document.documentElement;
    const start = element.scrollTop;
    const target = element.scrollHeight - window.innerHeight;
    const distance = target - start;
    let startTime: number | null = null;
  
    function scrollStep(timestamp: number): void {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const percentage = Math.min(progress / duration, 1);
      element.scrollTop = start + distance * easeInOutCubic(percentage);
  
      if (percentage < 1) {
        requestAnimationFrame(scrollStep);
      }
    }
  
    requestAnimationFrame(scrollStep);
  }
  
  function easeInOutCubic(t: number): number {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  }

  const user = {
    fr:{
      firstName: 'Jean',
      lastName: 'Pruski',
      bio: 'Développeur // Overlay Evangelist',
      birthDate: '03/03/89',
      phone: '+33.6.81.62.1000',
      mail: 'jean.pruski@gmail.com',
      street: '90 avenue Jean Jaurès',
      zipCode: 'F - 75019 PARIS'
    },
    en:{
      firstName: 'Jean',
      lastName: 'Pruski',
      bio: 'Developer // Overlay Evangelist',
      birthDate: '03/03/89',
      phone: '+33.6.81.62.1000',
      mail: 'jean.pruski@gmail.com',
      street: '90 avenue Jean Jaurès',
      zipCode: 'F - 75019 PARIS'
    }
  };

  let userInfos = user[isFrench ? 'fr' : 'en'];

  return (
    <_AppWrapper ref={topRef}>
      <_AppWrapper__DarkModeSwitch>
        <Switch
          handler={handleDarkMode}
          darkMode={darkMode}
          bool={darkMode}
          ico="darkmode"
        />
      </_AppWrapper__DarkModeSwitch>
      
      <_AppWrapper__i18nSwitch>
        <Switch
          handler={handleLang}
          darkMode={darkMode}
          bool={isFrench}
          ico="lang"
        />
      </_AppWrapper__i18nSwitch>

      <Layout 
        user={userInfos}
        darkMode={darkMode}
        isFrench={isFrench}
        toggleTab={toggleTab}
        isTabOpen={isTabOpen}
        handleScroll={handleScroll}
      />
      <_AppWrapper__Overlay
        style={{backgroundColor: utilsService.darkModeBackgroundColorHandler(darkMode)}}
      />
      <CoreThreeBackground/>
    </_AppWrapper>
  );
};

const
  _AppWrapper = APP_CSS._AppWrapper,
  _AppWrapper__DarkModeSwitch = APP_CSS._AppWrapper__DarkModeSwitch,
  _AppWrapper__i18nSwitch = APP_CSS._AppWrapper__i18nSwitch,
  _AppWrapper__Overlay = APP_CSS._AppWrapper__Overlay;

export default App;