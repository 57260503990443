import styled from 'styled-components';
import VARIABLES_CSS from '../VariablesCss';

const picturePath = '../../../img/picture.png';  

const TopInformations = {
  _TopInformationsWrapper: styled.div`
    justify-content: space-between;
    position: sticky;
    display: flex;
    width: 100%;

    @media (max-width: 1024px) {
      position: relative;
      padding: calc(2 * ${VARIABLES_CSS.spacer.xxl}) 0;
    }
  `,
  _TopInformations__Picture: styled.div`
    background-image: url(${picturePath});
    background-size: cover;

    height: ${VARIABLES_CSS.global.userPictureHeight};
    width: ${VARIABLES_CSS.global.userPictureWidth};
    transition: opacity .8s, transform .2s; 
    transform-origin: bottom;

    &.picture-hide{
      opacity: 0;
      transform: scaleY(0);
    }

    &.picture-show{
      opacity: 1;
      transform: scaleY(1);
    }

    @media (max-width: 1024px) {
      display: none;
    }
  `,
  _TopInformations__ScrollButton: styled.div`
    transition: opacity .4s, left .5s;
    display: flex;
    align-items: center;
    gap: ${VARIABLES_CSS.spacer.sm};
    position: absolute;
    bottom: 18px;

    &.picture-hide{
      left: -5px;
      opacity: 1;
    }

    &.picture-show{
      left: 700px;
      opacity: 0;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  `,
  _TopInformations__InformationsContainer: styled.div`
    text-align: right;
    
    @media (max-width: 1024px) {
      text-align: center;
      width: 100%;
    }
  `
};

export default TopInformations;