import styled from 'styled-components';
import VARIABLES_CSS from '../VariablesCss';

const LayoutCss = {
  _LayoutWrapper: styled.div`
    transform: scale(.95);
    max-width: ${VARIABLES_CSS.global.layoutWidth};
    padding: ${VARIABLES_CSS.spacer.md};
    padding-left: calc(2 * ${VARIABLES_CSS.spacer.xxl});
    flex-direction: column;
    display: flex;
    margin: auto;
    width: 100%;
    z-index: 1;

    @media (max-width: 1024px) {
      padding: 0;
      max-width: 100%;
    }
  `,
  _Layout__BottomInformations: styled.div`
    gap: ${VARIABLES_CSS.spacer.xxl};
    position: sticky;
    display: flex;
    width: 100%;
    
    @media (max-width: 1024px) {
      flex-direction: column;
      position: relative;
    }
  `,
  _Layout__BottomInformations__LeftPanel: styled.div`

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  `,
  _Layout__BottomInformations__RightPanel: styled.div`
    display flex;
    width: 100%
  `
};

export default LayoutCss;