const VariablesCss = {
  color: {
    black: '#000',
    white: '#fff',
    lightBlack: 'rgba(000, 000, 000, 0.85)',
    lightWhite: 'rgba(255, 255, 255, 0.85)'
  },
  global: {
    layoutWidth: '900px',
    userPictureWidth: '180px',
    userPictureHeight: '180px',
    pictoSizes: '30px',
  },
  spacer: {
    xxs: '.5rem',      
    xs: '1rem',    
    sm: '1.5rem',   
    md: '2rem',  
    lg: '2.5rem', 
    xl: '3rem',
    xxl: '3.5rem',
  },
  fontSize: {
    xxs: '',      
    xs: '',    
    sm: '',   
    md: '',  
    lg: '2rem', 
    xl: '',
    xxl: '6rem'
  },
  letterSpacing: {
    xxs: '.5rem',
    xs: '1rem'
  }
}

export default VariablesCss;