import { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import utilsService from '../../services/utilsService';
import { MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from "react-icons/md";

interface ButtonProps {
  handleClick?: () => void,
  darkMode?: boolean,
  arrow?: string,
}

const Button: FC<ButtonProps> = ({ handleClick, darkMode, arrow }) => {

  useEffect(() => {
   
  }, []);
  
  return (
    <_ButtonWrapper
      onClick={handleClick}
      style={{
        border: utilsService.darkModeBorderHandler(darkMode, 3),
      }}
    >
      <_Switch
        style={{backgroundColor: utilsService.darkModeColorHandler(darkMode)}}
      >
        {arrow == 'bottom' && <MdOutlineKeyboardArrowDown style={{fill: utilsService.darkModeColorHandler(!darkMode)}}/>}
        {arrow == 'top' && <MdOutlineKeyboardArrowUp style={{fill: utilsService.darkModeColorHandler(!darkMode)}}/>}

      </_Switch>
    </_ButtonWrapper>
  );
};

const
  _ButtonWrapper = styled.div`
    z-index: 2;
    cursor: pointer;
    transition: all 0.3s;
    height: 22px;
    width: 22px;
    left: 3px;
    border-radius: 20px;
    opacity: 0.95;
    z-index: 100;
    background-color: transparent;
    position: relative;

    @media (max-width: 700px) {
      left: 20px;
      top: 20px;
    }

    &:hover {
      & > div{
        opacity: 0.9;
        transform: scale(1.4);
      }

      & svg{
        transform: scale(2);
        opacity: .8;
      }
    }
  `,_Switch = styled.div`
    height: 16px;
    width: 16px;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 50%;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg{
      width: 22px;
      height: 22px;
      transition: transform .3s, opacity .3s;
      transform: scale(1.4);
    }
  `;

export default Button;