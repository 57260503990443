import { FC, useEffect, useState } from 'react';
import NAVIGATION_CSS from '../../style/layouts/NavigationCss';
import utilsService from '../../services/utilsService';
import PictoDisplayer from '../core/PictoDisplayer';
import Button from '../core/Button';

interface NavigationProps {
  darkMode: boolean,
  toggleTab: () => void
}

const Navigation: FC<NavigationProps> = ({ darkMode, toggleTab }) => {
  const [top, setTop] = useState<string>('');

  useEffect(() => {
    const handleScroll = () => {
      const windowWidth = window.innerWidth;
      if(windowWidth > 1024){
        const bumperElementHeight = utilsService.getElementHeight('topInformations');
        const secondBumperElementHeight = utilsService.getElementHeight('minorInformations');
        const windowHeightDecal = utilsService.calculateWindowTopDecal();
        const topResult = bumperElementHeight + secondBumperElementHeight;
        setTop(`calc(${windowHeightDecal + topResult}px)`);
      };
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollTopFunc = () => {
    const currentScroll = window.scrollY;
    const scrollDistance = 10000;
    const totalFrames = 60 * 3;
  
    const scrollStep = scrollDistance / totalFrames;
  
    function scrollAnimation(currentFrame: number) {
      if (currentFrame <= totalFrames) {
        const newScrollPosition = currentScroll - scrollStep * currentFrame;
        window.scrollTo(0, newScrollPosition);
        requestAnimationFrame(() => scrollAnimation(currentFrame + 1));
      }
    }
  
    scrollAnimation(1);
  }

  return (
    <_NavigationWrapper
      id='navigation'
      style={{
        top,
        borderTop: utilsService.darkModeBorderHandler(darkMode)
      }}
    >
      <PictoDisplayer
        link="https://www.linkedin.com/in/jeanpruski/"
        picto={'linkedin'}
      />
      <PictoDisplayer
        link="https://github.com/jeanpruski"
        picto={'github'}
        />
      {/* <PictoDisplayer
        picto={'globe'}
        onClickFunc={toggleTab}
      /> */}
      <span>
        <Button
          darkMode={darkMode}
          handleClick={scrollTopFunc}
          arrow='top'
        ></Button>
      </span>
    </_NavigationWrapper>
  );
};

const
  _NavigationWrapper = NAVIGATION_CSS._NavigationWrapper;

export default Navigation;