import styled from 'styled-components';
import VARIABLES_CSS from '../VariablesCss';

const Experiences = {
  _ExperiencesWrapper: styled.div`
    margin-bottom: ${VARIABLES_CSS.spacer.xxl};
    padding-top: ${VARIABLES_CSS.spacer.lg};
    gap: ${VARIABLES_CSS.spacer.xl};
    flex-direction: column-reverse;
    transition: transform .5s, background-color .5s, backdrop-filter .3s;
    position: sticky;
    transform-origin: center top;

    display: flex;
    width: 100%;

    &.tab-close{
      backdrop-filter: blur(10px);
      transform: scaleY(0);
      background-color: #80808005;
    }

    &.tab-open{
    }

    @media (max-width: 1024px) {
      padding-top: ${VARIABLES_CSS.spacer.xxl};
      position: relative;
    }
  `,
  _ExperiencesWrapper__absolute: styled.div`
    position: relative;
  `,
  _Experiences__ScrollButton: styled.div`
    transform-origin: right;
    transition: all .8s;
    align-items: center;
    position: absolute;
    display: flex;
    bottom: -10px;
    display: flex;
    left: -148px;

    &.unblur-element{
      opacity: 0;
      transform: scaleX(0);
    }

    &.blur-element{
      opacity: 1;
    }

    @media (max-width: 1024px) {
      display: none;
    }
    
    & p {
      margin-right: ${VARIABLES_CSS.spacer.xxs};
      text-align: right;
    }
  `,
  _Experiences__ScrollCGV: styled.div`
    gap: ${VARIABLES_CSS.spacer.lg};
    transition: opacity 2s;
    position: absolute;
    display: flex;
    bottom: -40px;
    right: 0px;

    &.unblur-element{
      opacity: 1;
    }

    &.blur-element{
      opacity: 0;
    }
  `,
  _Experience__Overlay: styled.div`
    transition: backdrop-filter 1s;
    height: calc(100% + 40px);
    width: calc(100% + 30px);
    position: absolute;
    bottom: -20px;
    left: -15px;
    right: -15px;
    z-index: -1;
    top: -24px;

    &.unblur-element{
      backdrop-filter: blur(0px);
    }

    &.blur-element{
      backdrop-filter: blur(5px);
    }
  `,
  _ExperienceWrapper: styled.div`
    transition: transform .3s;
    cursor: pointer;

    &:hover{
      transform: scale(1.02);
    }
  `,
  _Experience__TopInformations: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  _Experience__TopInformations_MajorInformation: styled.div`
    align-items: flex-end;
    display: flex;
    
    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
    }
  `,
  _Experience__TopInformations_MinorInformation: styled.div`
    align-items: flex-end;
    display: flex;
  `,
  _Experience__BottomInformations: styled.div`
    max-height: 500px; /* Utilisez une valeur fixe suffisamment grande */
    overflow: hidden;
    transition: padding .4s, margin .4s, max-height .4s;

    &.experience-closed {
      padding: 0 ${VARIABLES_CSS.spacer.sm};
      margin: ${VARIABLES_CSS.spacer.sm} 0;
      max-height: 0;
      overflow: hidden;
    }

    &.experience-opened {
      padding: ${VARIABLES_CSS.spacer.sm};
      margin: 0;
      max-height: 500px; /* Utilisez la même valeur que celle définie dans la classe de base */
    }
  `,
  _Experience__Info: styled.div`
    margin-bottom: ${VARIABLES_CSS.spacer.xxs};
  `,
  _Experience__Picto: styled.div`
    margin-bottom: ${VARIABLES_CSS.spacer.xl};
    margin-top: ${VARIABLES_CSS.spacer.xs};
    gap: ${VARIABLES_CSS.spacer.xs};
    display: flex;
  `,
};

export default Experiences;