var EXPERIENCES = {
  "fr": [
    {
      "society" : "Markentive",
      "location" : "// F - Paris",
      "years" : "2022 - ∞",
      "job1" : "/ Développeur Fullstack",
      "job2" : "",
      "description1" : "Aide les entreprises à synchroniser leurs données (BDD, ERP, Data lake, SAAS, ...) avec leur CRM en déployant des systèmes robustes et sécurisés (AWS, Azure, nodeJs, Python, ...).",
      "description2" : "Elaboration et implémentation des stratégies, architectures et action de developpement web (React, Jinja/Hubl).",
      "description3" : "",
      "description4" : "",
      "skills" : ['aws', 'nodejs', 'python', 'react', 'hubspot', 'html', 'css', 'gitlab', 'figma', 'notion']
    },
    {
      "society" : "Placeloop",
      "location" : "// F - Paris",
      "years" : "2019 - 2022",
      "job1" : "/ Développeur Frontend",
      "job2" : "",
      "description1" : "Accountable de la transversale publicilité (Facebook & Instagram).",
      "description2" : "Développe les interfaces de la plateforme (Angular JS, Angular)",
      "description3" : "Réalise le storeLocator, les landing pages et eMailings (React, HTML/CSS).",
      "description4" : "",
      "skills" : ['angular', 'react', 'nodejs', 'html', 'css', 'github', 'bitbucket', 'figma', 'jira']
    },
    {
      "society" : "Medialis",
      "location" : "// F - Paris",
      "years" : "2016 - 2018",
      "job1" : "/ Responsable Evénementiel",
      "job2" : "/ Chargé de communication",
      "description1" : "Contribution aux actions de communication.",
      "description2" : "Création des chartes graphiques, skillss et autres projets d'infographie.",
      "description3" : "Réalisation d'ouvrages et supports de communication (plaquettes, flyers, ...).",
      "description4" : "Garant de l'organisation ainsi que du bon déroulement des événements.",
      "skills" : ['js', 'html', 'css', 'ai', 'ps', 'id']
    },
    {
      "society" : "Concept Store",
      "location" : "// L - Luxembourg",
      "years" : "2014 - 2015",
      "job1" : "/ Graphiste - Associate",
      "job2" : "",
      "description1" : "Création de l'identité visuelle.",
      "description2" : "Réalisation d'outils de communication.",
      "description3" : "Développement projet eShop, création de Webdesign.",
      "description4" : "",
      "skills" : ['js', 'html', 'css', 'ai', 'ps']
    },
    // {
    //   "society" : "Vitaly Uomo",
    //   "location" : "// L - Luxembourg",
    //   "years" : "2012 - 2014",
    //   "job1" : "/ Styliste - Tailleur",
    //   "job2" : "",
    //   "description1" : "Création de lignes de costume.",
    //   "description2" : "Fitting, Tailleur machine.",
    //   "description3" : "",
    //   "description4" : "",
    //   "skills" : ['machine', 'ai']
    // },
    // {
    //   "society" : "Domyos",
    //   "location" : "// F - Lille",
    //   "years" : "2011 - 2012",
    //   "job1" : "/ Modéliste Référent périmètre Baby",
    //   "job2" : "",
    //   "description1" : "Modélisme et prototypage 3D (Lectra V7).",
    //   "description2" : "",
    //   "description3" : "",
    //   "description4" : "",
    //   "skills" : ['couture', 'ai']
    // },
    // {
    //   "society" : "Yukiko Tsunoda",
    //   "location" : "// F - Paris",
    //   "years" : "2010 - 2011",
    //   "job1" : "/ Styliste - Modéliste",
    //   "job2" : "",
    //   "description1" : "Réalisation des patrons et pièces d'études.",
    //   "description2" : "Prototypage machine des modèles.",
    //   "description3" : "",
    //   "description4" : "",
    //   "skills" : ['couture', 'machine', 'ai']
    // },
    {
      "society" : "The Knight Cats",
      "location" : "// F - Paris",
      "years" : "2010 - ∞",
      "job1" : "",
      "job2" : "",
      "description1" : "Création de l'identité visuelle - production de House Music.",
      "description2" : "Nervous Records (USA), Trax Records (USA), 124 Recordings (UK), LuvDancin (SP), MLH (FR), ...",
      "description3" : "",
      "description4" : "",
      "skills" : ['live', 'song', 'ai', 'ps']
    },
    // {
    //   "society" : "Decathlon",
    //   "location" : "// F - Lille",
    //   "years" : "2009 - 2010",
    //   "job1" : "/ Modéliste",
    //   "job2" : "",
    //   "description1" : "Création des patrons de pièces d'études.",
    //   "description2" : "",
    //   "description3" : "",
    //   "description4" : "",
    //   "skills" : ['couture', 'machine', 'ai']
    // },
    {
      "society" : "Modéliste",
      "location" : "// F - Paris Lille - // L - Luxembourg",
      "years" : "2009 - 2014",
      "job1" : "/ Domyos - Yukiko Tsunoda - Decathlon - Vitaly Uomo",
      "job2" : "",
      "description1" : "Modélisme et prototypage 3D (Lectra V7).",
      "description2" : "Réalisation des patrons et pièces d'études.",
      "description3" : "Fitting, Tailleur machine.",
      "description4" : "",
      "skills" : ['couture', 'machine', 'ai', 'ps']
    }
  ],
  "en": [
    {
      "society" : "Markentive",
      "location" : "// F - Paris",
      "years" : "2022 - ∞",
      "job1" : "/ Fullstack Developer",
      "job2" : "",
      "description1" : "Assists businesses in synchronizing their data (Databases, ERP, Data lake, SAAS, ...) with their CRM by deploying robust and secure systems (AWS, Azure, Node.js, Python, ...).",
      "description2" : "Development and implementation of web development strategies, architectures, and actions (React, Jinja/Hubl).",
      "description3" : "",
      "description4" : "",
      "skills" : ['aws', 'nodejs', 'python', 'react', 'hubspot', 'html', 'css', 'gitlab', 'figma', 'notion']
    },
    {
      "society" : "Placeloop",
      "location" : "// F - Paris",
      "years" : "2019 - 2022",
      "job1" : "/ Frontend Developer",
      "job2" : "",
      "description1" : "Accountable for cross-platform advertising (Facebook & Instagram).",
      "description2" : "Development of the interfaces of the platform (Angular JS, Angular).",
      "description3" : "Creation of the store locator, landing pages, and emailings (React, HTML/CSS).",
      "description4" : "",
      "skills" : ['angular', 'react', 'nodejs', 'html', 'css', 'github', 'bitbucket', 'figma', 'jira']
    },
    {
      "society" : "Medialis",
      "location" : "// F - Paris",
      "years" : "2016 - 2018",
      "job1" : "/ Event Manager",
      "job2" : "/ Communication Officer",
      "description1" : "Contribution to communication activities.",
      "description2" : "Creation of graphic charters, skills, and other infographic projects.",
      "description3" : "Creation of communication materials (brochures, flyers, etc.).",
      "description4" : "Ensures the organization and smooth running of events.",
      "skills" : ['js', 'html', 'css', 'ai', 'ps', 'id']
    },
    {
      "society" : "Concept Store",
      "location" : "// L - Luxembourg",
      "years" : "2014 - 2015",
      "job1" : "/ Graphic Designer - Associate",
      "job2" : "",
      "description1" : "Creation of visual identity.",
      "description2" : "Creation of communication tools.",
      "description3" : "eShop project development, web design creation.",
      "description4" : "",
      "skills" : ['js', 'html', 'css', 'ai', 'ps']
    },
    {
      "society" : "The Knight Cats",
      "location" : "// F - Paris",
      "years" : "2010 - ∞",
      "job1" : "",
      "job2" : "",
      "description1" : "Creation of visual identity - production of House Music.",
      "description2" : "Nervous Records (USA), Trax Records (USA), 124 Recordings (UK), LuvDancin (SP), MLH (FR), ...",
      "description3" : "",
      "description4" : "",
      "skills" : ['live', 'song', 'ai', 'ps']
    },
    {
      "society" : "Pattern Maker",
      "location" : "// F - Paris Lille - // L - Luxembourg",
      "years" : "2009 - 2014",
      "job1" : "/ Domyos - Yukiko Tsunoda - Decathlon - Vitaly Uomo",
      "job2" : "",
      "description1" : "Pattern making and 3D prototyping (Lectra V7).",
      "description2" : "Creation of patterns and study pieces.",
      "description3" : "Fitting, Machine sewing.",
      "description4" : "",
      "skills" : ['couture', 'machine', 'ai', 'ps']
    }
  ]
}
  
export default EXPERIENCES;