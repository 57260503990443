import { FC, useEffect, useState } from 'react';
import MINORINFORMATIONS_CSS from '../../style/layouts/MinorInformationsCss';
import utilsService from '../../services/utilsService';

interface MinorInformationsProps {
  birthDate: string,
  phone: string,
  mail: string,
  street: string,
  zipCode: string
}

const MinorInformations: FC<MinorInformationsProps> = ({ birthDate, phone, mail, street, zipCode }) => {
  const [top, setTop] = useState<string>('');

  useEffect(() => {
    const handleScroll = () => {
      const windowWidth = window.innerWidth;
      if(windowWidth > 1024){
        const bumperElementHeight = utilsService.getElementHeight('topInformations');
        const windowHeightDecal = utilsService.calculateWindowTopDecal();
        if(bumperElementHeight) setTop(`calc(${windowHeightDecal + bumperElementHeight}px)`);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <_MinorInformationWrapper
      id='minorInformations'
      style={{top}}
    >
      <_MinorInformation__BirthDate>{birthDate}</_MinorInformation__BirthDate>
      <_MinorInformation__Phone>{phone}</_MinorInformation__Phone>
      <_MinorInformation__Mail>{mail}</_MinorInformation__Mail>
      <_MinorInformation__Street>{street}</_MinorInformation__Street>
      <_MinorInformation__ZipCode>{zipCode}</_MinorInformation__ZipCode>
    </_MinorInformationWrapper>
  );
};

const
  _MinorInformationWrapper = MINORINFORMATIONS_CSS._MinorInformationsWrapper,
  _MinorInformation__BirthDate = MINORINFORMATIONS_CSS._MinorInformation__BirthDate,
  _MinorInformation__Phone = MINORINFORMATIONS_CSS._MinorInformation__Phone,
  _MinorInformation__Mail = MINORINFORMATIONS_CSS._MinorInformation__Mail,
  _MinorInformation__Street = MINORINFORMATIONS_CSS._MinorInformation__Street,
  _MinorInformation__ZipCode = MINORINFORMATIONS_CSS._MinorInformation__ZipCode;

export default MinorInformations;