import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import * as THREE from 'three';

const rotate = keyframes`
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0.16;
  }

  100% {
    opacity: 0.16;
  }
`;

const CoreThreeBackground: React.FC = React.memo(() => {
  const sceneRef = useRef<HTMLDivElement | null>(null);
  const [geometry, setGeometry] = useState<THREE.TorusGeometry | null>(null);
  const [material, setMaterial] = useState<THREE.MeshBasicMaterial | null>(null);

  const { scene, camera, renderer, torus } = useMemo(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);

    const geometry = new THREE.TorusGeometry(10, 10, 50, 50);
    const material = new THREE.MeshBasicMaterial({ color: 'grey', wireframe: true });
    const torus = new THREE.Mesh(geometry, material);
    scene.add(torus);

    camera.position.z = 150;
    camera.position.x = 0;
    camera.position.y = -1;

    setGeometry(geometry);
    setMaterial(material);

    return { scene, camera, renderer, torus };
  }, []);

  useEffect(() => {
    if (sceneRef.current && geometry && material) {
      sceneRef.current.appendChild(renderer.domElement);
      const directionalLight = new THREE.DirectionalLight('white', 100000);
      scene.add(directionalLight);

      const animate = () => {
        requestAnimationFrame(animate);
        torus.rotation.x += 0.001;
        torus.rotation.y += 0.0005;
        
        if (camera.position.z > 8) {
          camera.position.z -= 1.8;
        }
        renderer.render(scene, camera);
      };

      animate();

      return () => {
        geometry.dispose();
        material.dispose();
        renderer.dispose();
      };
    }
  }, [sceneRef, renderer, torus, camera, geometry, material]);

  return (
    <Wrapper>
      <div ref={sceneRef} />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  opacity: 0.16;
  animation: ${rotate} .5s;

  @media (max-width: 1024px){
    animation: unset;
  }
`;

export default CoreThreeBackground;
