import React from 'react';
import styled from 'styled-components';
import utilsService from '../../services/utilsService';
import { BsFillFlagFill } from "react-icons/bs";
import { FaFlag, FaFlagUsa } from "react-icons/fa6";
import { RiMoonLine, RiSunLine } from "react-icons/ri";

interface SwitchProps {
  bool: boolean;
  darkMode: boolean;
  ico?: string;
  handler: () => void;
}

const Switch: React.FC<SwitchProps> = ({ bool, darkMode, handler, ico }) => (
  <_Wrapper
    onClick={handler}
    style={{
      border: utilsService.darkModeBorderHandler(darkMode, 3),
      color: utilsService.darkModeColorHandler(darkMode),
    }}
  >
    <_Switch
      style={{
        backgroundColor: utilsService.darkModeColorHandler(darkMode),
        left: bool ? '3px' : '20px',
      }}
    >
    </_Switch>

    {ico === 'lang' ? (
      bool === true ? (
        <FaFlag />
      ) : (
        <FaFlagUsa />
      )
    ) : ico === 'darkmode' ? (
      bool === true ? (
        <RiMoonLine />
      ) : (
        <RiSunLine />
      )
    ) : (
      <></>
    )}
  </_Wrapper>
);

// {darkMode ? (
//   <BsSun />

// ) : (
//   <LuSunMedium />

// )}



const
  _Wrapper = styled.div`
    cursor: pointer;
    transition: all 0.3s;
    position: relative;
    display: flex;
    height: 22px;
    width: 40px;
    border-radius: 20px;
    opacity: 0.8;
    z-index: 10;
    background-color: transparent;

    &:hover {
      & > div{
        opacity: 0.5;
      }
    }

    & svg{
      position: absolute;
      top: 1px;
      left: 50px;
      height: 22px;
      width: 22px;
    }
  `,_Switch = styled.div`
    height: 16px;
    width: 16px;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
  `;

export default Switch;
