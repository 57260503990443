import { FC } from 'react';
import styled from 'styled-components';

interface ButtonProps {
  height: number,
}

const Button: FC<ButtonProps> = ({ height=0 }) => {
  
  return (
    <_SpacerWrapper style={{ height: `${height}vh` }}/>
  );
};

const _SpacerWrapper = styled.div`
  @media (max-width: 1024px) {
    max-height: 0px;
  }
`

export default Button;