import styled, {keyframes} from 'styled-components';

const opacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const AppCss = {
  _AppWrapper: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    animation: ${opacity} 1s;
  `,
  _AppWrapper__DarkModeSwitch: styled.div`
    position: fixed;
    left: 10px;
    top: 60px;
    z-index: 5;

    @media (max-width: 1024px){
      position: absolute;
    }
  `,
  _AppWrapper__i18nSwitch: styled.div`
    position: fixed;
    left: 10px;
    top: 15px;
    z-index: 5;

    @media (max-width: 1024px){
      position: absolute;
    }
  `,
  _AppWrapper__Overlay: styled.div`
    transition: all .2s;
    max-width: 100vw;
    position: fixed;
    height: 100%;
    width:100%;
    bottom:0;
    right:0;
    left:0;
    top:0;
  `
};

export default AppCss;