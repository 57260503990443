import styled from 'styled-components';
import VARIABLES_CSS from '../VariablesCss';

const PictoDisplayer = {
  _PictoDisplayer: styled.a`
    height: ${VARIABLES_CSS.global.pictoSizes};
    width: ${VARIABLES_CSS.global.pictoSizes};

    > svg{
      height : 100%;
      width: 100%;
    }
  `
};

export default PictoDisplayer;