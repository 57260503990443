import styled from 'styled-components';
import VARIABLES_CSS from '../VariablesCss';

const MinorInformations = {
  _MinorInformationsWrapper: styled.div`
    width: ${VARIABLES_CSS.global.userPictureWidth};
    padding: ${VARIABLES_CSS.spacer.lg} 0;
    flex-direction: column;
    align-items: center;
    text-align:center;
    position: sticky;
    display: flex;

    @media (max-width: 1024px) {
      padding: 0;
      width: 100%;
      position: relative;
    }
  `,
  _MinorInformation__BirthDate: styled.div`
    font-weight: bold;
    font-size: 4rem;
  `,
  _MinorInformation__Phone: styled.div`
    margin-bottom:  ${VARIABLES_CSS.spacer.xxs};
    font-size: 2.5rem;
  `,
  _MinorInformation__Mail: styled.div`
    margin-bottom:  ${VARIABLES_CSS.spacer.xxs};
    font-size: 1.7rem;
    font-weight: bold;
  `,
  _MinorInformation__Street: styled.div`
    margin-bottom:  ${VARIABLES_CSS.spacer.xxs};
    font-size: 1.6rem;
  `,
  _MinorInformation__ZipCode: styled.div`
    font-size: 2.4rem;
  `,
};

export default MinorInformations;