import styled from 'styled-components';
import VARIABLES_CSS from './VariablesCss';

const StyleCss = {
  _H1: styled.h1`
    letter-spacing: ${VARIABLES_CSS.letterSpacing.xs};
    font-size: ${VARIABLES_CSS.fontSize.xxl};
    text-transform: uppercase;
    font-weight: bold;
  `,
  _H2: styled.h2`
    letter-spacing: ${VARIABLES_CSS.letterSpacing.xs};
    font-size: ${VARIABLES_CSS.fontSize.xxl};
    text-transform: capitalize;
  `,
  _H3: styled.h3`
    letter-spacing: ${VARIABLES_CSS.letterSpacing.xxs};
    margin-right: ${VARIABLES_CSS.spacer.xxs};
    font-size: ${VARIABLES_CSS.fontSize.lg};
    text-transform: uppercase;
    font-weight: bold;
  `,
  _H4: styled.h4`
    font-size: ${VARIABLES_CSS.fontSize.lg};
    margin-top: ${VARIABLES_CSS.spacer.sm};
    text-transform: capitalize;

  `,
  _H5: styled.h5`
  
  `,
  _H6: styled.h6`
    font-weight: bold;
    padding-bottom: 5px;
    // border-bottom: 1px solid black;
    text-decoration: underline;
  `,
  _P: styled.p`
    font-size: 1.6rem;
    line-height: 1.2;
  `,
  _P__Weight: styled.p`
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.2;
  `
}

export default StyleCss;